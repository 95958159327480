<template>
    <div id="broadcast" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-homemenu></app-homemenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <div class="kt-subheader__main">
                                <h3 class="kt-subheader__title">{{ $t("broadcast_title") }}</h3>
                                <span class="kt-subheader__separator kt-subheader__separator--v"></span>
                                <span class="kt-subheader__desc">{{ $t("broadcast_subTitle") }}</span>
                            </div>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->
                            <div class="row">
                                <div class="col-md-12">
                                    <!--begin::Portlet-->
                                    <div class="kt-portlet">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <path d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707zm2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 1 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708zm5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708zm2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM6 8a2 2 0 1 1 2.5 1.937V15.5a.5.5 0 0 1-1 0V9.937A2 2 0 0 1 6 8z" fill="#000000"/>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("broadcast_createBroadcast") }}
                                                </h3>
                                            </div>
                                        </div>
                                        <!--begin::Form-->
                                        <form class="kt-form kt-form--label-right" data-cy="NewBroadcastForm" autocomplete="off">
                                            <div class="kt-portlet__body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group validated">
                                                            <label class="colorGeonotif">{{ $t("broadcast_broadcastType") }} <br /></label>
                                                            <div class="kt-radio-list row" style="margin-top: 20px;">
                                                                <div class="col-2">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="newBroadcastBanner" type="radio" v-model="vType" name="bannerType" value="BANNER" checked /> {{ $t("broadcast_broadcastTypeBanner") }}
                                                                        <span></span>
                                                                    </label>
                                                                </div>
                                                                <div class="col-2">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="newBroadcastEmail" type="radio" v-model="vType" name="bannerType" value="EMAIL" /> {{ $t("broadcast_broadcastTypeEmail") }}
                                                                        <span></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div v-show="vType === 'BANNER'" class="kt-separator kt-separator--border-dashed " style="margin-bottom: 45px;"></div>

                                                <div class="row" v-show="vType === 'BANNER'">
                                                    <div class="col-md-12">
                                                        <div class="form-group validated">
                                                            <label class="colorGeonotif">{{ $t("broadcast_broadcastPeriod") }} </label>
                                                            <div class="row" style="margin-top: 20px;">
                                                                <div class="input-group date col-md-12" style="margin-top: -9px; ">
                                                                    <label class="col-form-label" style="margin-right: 10px;">{{ $t("broadcast_broadcastPeriodFrom") }} *</label>
                                                                    <div class="period input-group-prepend">
                                                                        <span> </span>
                                                                        <span class="input-group-text">
                                                                            <i class="la la-calendar"></i>
                                                                        </span>
                                                                    </div>
                                                                    <input type="text" id="broadcastStartDatePicker" :placeholder="$t('broadcast_PeriodFromPlaceholder')" class="form-control" />
                                                                    <label class="col-form-label" style="margin-left: 10px; margin-right: 10px; ">{{ $t("broadcast_broadcastPeriodTo") }} *</label>
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text">
                                                                            <i class="la la-calendar"></i>
                                                                        </span>
                                                                    </div>
                                                                    <input type="text" id="broadcastEndDatePicker" :placeholder="$t('broadcast_PeriodToPlaceholder')" class="form-control" />
                                                                    <div v-if="!$v.vStartDate.required && !$v.vEndDate.required" class="invalid-feedback" :style="user.preferredLanguage == 'fr' ? 'margin-left: 35px;' : 'margin-left: 50px;'">
                                                                        {{ $t("error_dateRequired") }}
                                                                    </div>
                                                                    <div v-if="!$v.vStartDate.required && $v.vEndDate.required" class="invalid-feedback" :style="user.preferredLanguage == 'fr' ? 'margin-left: 35px;' : 'margin-left: 50px;'">
                                                                        {{ $t("error_startDateRequired") }}
                                                                    </div>
                                                                    <div v-if="$v.vStartDate.required && !$v.vEndDate.required" class="invalid-feedback" :style="user.preferredLanguage == 'fr' ? 'margin-left: 35px;' : 'margin-left: 50px;'">
                                                                        {{ $t("error_endDateRequired") }}
                                                                    </div>
                                                                    <div v-if="!vIsCorrectDate && $v.vStartDate.required && $v.vEndDate.required" class="invalid-feedback" :style="user.preferredLanguage == 'fr' ? 'margin-left: 35px;' : 'margin-left: 50px;'">
                                                                        {{ $t("error_endDateIsNotCorrectForReport") }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="kt-separator kt-separator--border-dashed " style="margin-bottom: 45px;"></div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="form-group validated">
                                                            <label class="colorGeonotif" id="newBroadcastEnglishMessage">{{ $t("broadcast_broadcastMessage") }} <br /></label>
                                                            <div class="form-group form-group-last validated" style="margin-top: 20px;">
                                                                <label for="newBroadcastEnglishMessage">{{ $t("broadcast_broadcastMessageEnTitle") }} *</label>
                                                                <textarea id="newBroadcastEnglishMessage" class="form-control" rows="5" v-model="vMessageEn"  @keyup="onChangeForm"></textarea>
                                                                <div v-if="!$v.vMessageEn.required" class="invalid-feedback">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                                <span class="form-text text-muted">{{ $t("broadcast_broadcastMessageEnDesc") }}</span>
                                                            </div>
                                                            <div class="form-group form-group-last validated" style="margin-top: 20px;">
                                                                <label for="newBroadcastFrenchMessage">{{ $t("broadcast_broadcastMessageFrTitle") }} *</label>
                                                                <textarea id="newBroadcastFrenchMessage" class="form-control" rows="5" v-model="vMessageFr"  @keyup="onChangeForm"></textarea>
                                                                <div v-if="!$v.vMessageFr.required" class="invalid-feedback">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                                <span class="form-text text-muted">{{ $t("broadcast_broadcastMessageFrDesc") }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions">
                                                    <div class="row">
                                                        <div class="col-lg-6 kt-align-right"></div>
                                                        <div class="col-lg-6 kt-align-right">
                                                            <button id="newBroadcastCreateButton" @click="onSpreadButton" type="button" :disabled="!enableSpreadButton" class="btn btn-brand kt-margin-r-5">
                                                                {{ $t("common_spread") }}
                                                            </button>
                                                            <button id="newBroadcastCancelButton" @click="onCancelButton" type="button" class="btn btn-secondary">{{ $t("common_cancel") }}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <!--end::Form-->
                                    </div>
                                    <!--end::Portlet-->
                                </div>
                            </div>
                            <!-- end:: Content -->
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->

                    <!-- begin:: Modal to confirm leave page -->
                    <app-confirmleavepagemodal ref="confirmleavepagemodalref"></app-confirmleavepagemodal>
                    <!-- end::Modal -->

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import i18n from "../../i18n";
import commonVueHelper from "../../helpers/commonVueHelper";

export default {
    data() {
        return {
            vType: "BANNER",
            vMessageEn: "",
            vMessageFr: "",
            vStartDate: null,
            vEndDate: null,
            vIsCorrectDate: true,
            isMounted: false,
            isFormDataChanged: false
        };
    },
    created: function() {
        console.log("Component(Broadcast)::created() - called");
        this.getAllBroadcastMessages();
    },
    mounted: function() {
        console.log("Component(Broadcast)::mounted() - Init metronic layout");
        KTLayout.init();
        this.isMounted = true;
    },
    destroyed: function() {
        console.log("Component(Broadcast)::destroyed() - called");
        this.destroyDatePicker();
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        vMessageEn: {
            required
        },
        vMessageFr: {
            required
        },
        vStartDate: {
            required: requiredIf(function() {
                return this.vType === "BANNER"
            })
        },
        vEndDate: {
            required: requiredIf(function() {
                return this.vType === "BANNER"
            })
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        vType() {
            console.log("Component(Broadcast)::watch(vType) called with : ", this.vType);
            if (this.vType !== "BANNER") {
                this.vIsCorrectDate = true;
                if (!this.emptyMessage) {
                    // To enable button "Spread"
                    this.onChangeForm();
                }
            } else {
                if (this.vEndDate && this.vStartDate > this.vEndDate) {
                    this.vIsCorrectDate = false;
                } else {
                    this.vIsCorrectDate = true;
                }
            }
        },

        broadcastMessages(newBroadcastMessages) {
            console.log("Component(Broadcast)::watch(broadcastMessages) called with : ", newBroadcastMessages);
            if (newBroadcastMessages) {
                for (let broadcastMessage of newBroadcastMessages) {
                    if (broadcastMessage.type === "BANNER") {
                        this.vMessageFr = broadcastMessage.messageFR;
                        this.vMessageEn = broadcastMessage.messageEN;
                        this.vStartDate = new Date(broadcastMessage.startDate);
                        this.vEndDate = new Date(broadcastMessage.endDate);
                        break;
                    }
                }
                this.initStartDatePicker();
                this.initEndDatePicker();
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["user", "broadcastMessages"]),

        enableSpreadButton: function() {
            if (!this.$v.$invalid && this.vIsCorrectDate && this.isFormDataChanged) {
                return true;
            }
            return false;
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["spreadBannerBroadcast", "spreadEmailBroadcast", "getAllBroadcastMessages"]),

        // Function called when user click on the "Cancel" button
        onCancelButton() {
            console.log("Component(Broadcast)::onCancel() - called");
            this.$router.push({ name: "home", params: { fromAction: "cancelButton" } });
        },

        // Function called when user click on the "Spread" button
        onSpreadButton() {
            console.log("Component(Broadcast)::onSpreadButton() - called");
            // Prepare body
            let payload = {
                messageFR: this.vMessageFr,
                messageEN: this.vMessageEn
            };
            // Verify if it banner message to add the start date and time
            if (this.vType && this.vType === "BANNER") {
                // Add the start date and end date
                payload["startDate"] = commonVueHelper.getStartOfDayToISOString(this.vStartDate);
                payload["endDate"] =  commonVueHelper.getEndOfDayToISOString(this.vEndDate);
                // Call the broadcast banner
                this.spreadBannerBroadcast(payload);
            } else {
                payload.messageFR = commonVueHelper.preventHtmlInjection(payload.messageFR);
                payload.messageEN = commonVueHelper.preventHtmlInjection(payload.messageEN);
                // Call the broadcast email
                this.spreadEmailBroadcast(payload);
            }
        },

        isEmptyForm() {
            // Return true if all control form are empty
            let isEmptyForm = true;
            if (this.vStartDate || this.vEndDate || this.vMessageEn || this.vMessageFr) {
                isEmptyForm = false;
            }
            return isEmptyForm;
        },

        initStartDatePicker() {
            let selector = $("#broadcastStartDatePicker");
            commonVueHelper.initDatePicker(selector, this.vStartDate);
            selector.on("changeDate", event => {
                if (event.target.value) {
                    this.vStartDate = new Date(event.date.valueOf());
                } else {
                    this.vStartDate = null;
                }
                if (this.vEndDate && this.vStartDate > this.vEndDate) {
                    this.vIsCorrectDate = false;
                } else {
                    this.vIsCorrectDate = true;
                }
                this.onChangeForm();
            });
        },

        initEndDatePicker() {
            let selector = $("#broadcastEndDatePicker");
            commonVueHelper.initDatePicker(selector, this.vEndDate);
            selector.on("changeDate", event => {
                if (event.target.value) {
                    this.vEndDate = new Date(event.date.valueOf());
                } else {
                    this.vEndDate = null;
                }
                if (this.vStartDate && this.vStartDate > this.vEndDate) {
                    this.vIsCorrectDate = false;
                } else {
                    this.vIsCorrectDate = true;
                }
                this.onChangeForm();
            });
        },

        destroyDatePicker() {
            $("#broadcastStartDatePicker").datepicker('destroy');
            $("#broadcastEndDatePicker").datepicker('destroy');
        },

        isEmptyMessage() {
            if (this.vMessageEn && this.vMessageFr) {
                return false;
            }
            return true;
        },

        onChangeForm() {
            this.isFormDataChanged = true;
        }

    },
    components: {},
    filters: {},

    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        commonVueHelper.displayConfirmModalOnLeavePage(this, "Broadcast", "create", !this.isEmptyForm() && this.isFormDataChanged, to, from, next);
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
